﻿// JS Dependencies:
//import '@ledgerhq/hw-transport-webhid';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import Icx from "./wwwroot/assets/vendor/hw-app-icx/Icx2.js"
import IconSDK, {
    IconBuilder,
    IconConverter,
    IconUtil,
    HttpProvider,
    SignedTransaction,
} from 'icon-sdk-js';

const PATH = "44'/4801368'/0'/0'";
var transport = null;

export async function listLedgerAddresses(count = 20) {
    // connects to a ledger device and retrieve a set amount of ICON
    // addresses (count)
    let addressData = [
        // {
        //   bip44Path: "44'/4801368'/0'/0'/n'" // bip44 path of the address
        //   icxAddress: 'hx49394...' // ICX address for the corresponding bip44path
        // }
    ];

    try {

        if (transport == null || transport == undefined) {
            // connects to ledger device via webhid
            transport = await TransportWebHID.create();
        }

        const appIcx = new Icx(transport);
        for (let i = 0; i < count; i++) {
            let currentPath = PATH + `/${i.toString()}'`;
            const icxAddress = await appIcx.getAddress(currentPath, false, true);

            addressData.push({
                bip44Path: currentPath,
                icxAddress: icxAddress.address.toString()
            });
        }

        return { transport, addressData };
    } catch (err) {
        // handles error
        console.log("error: ");
        console.log(err);

        if (transport)
            transport.close();

        return null;
    }
}

///tx = json transaction object
export async function signLedgerTransaction(path, tx) {
    try {

        const rawTransaction = icon_sdk_js__WEBPACK_IMPORTED_MODULE_2__.Converter.toRawTransaction(tx.params);
        const hashKey = icon_sdk_js__WEBPACK_IMPORTED_MODULE_2__.Util.generateHashKey(rawTransaction);

        if (transport == null || transport == undefined) {
            // connects to ledger device via webhid
            transport = await TransportWebHID.create();
        }

        const appIcx = new Icx(transport);
        const { signedRawTxBase64 } = await appIcx.signTransaction(path, hashKey);
        rawTransaction.signature = signedRawTxBase64;

        return {
            getProperties: () => rawTransaction,
            getSignature: () => signedRawTxBase64
        };

    } catch (err) {
        // handles error
        console.log("error: ");
        console.log(err);

        if (transport)
            transport.close();

        return null;
    }
}